import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { generate as passwordGenerator } from 'generate-password';
import * as taiPasswordStrength from 'tai-password-strength';
import TextField from './TextField';
import PasswordField from './PasswordField';
import clsx from 'clsx';

const PASSWORD_STRENGTH_VERY_WEAK = 'VERY_WEAK';
const PASSWORD_STRENGTH_WEAK = 'WEAK';
const PASSWORD_STRENGTH_REASONABLE = 'REASONABLE';
const PASSWORD_STRENGTH_STRONG = 'STRONG';
const PASSWORD_STRENGTH_VERY_STRONG = 'VERY_STRONG';

const PASSWORD_STRENGTH_LABELS = {
  [PASSWORD_STRENGTH_VERY_WEAK]: 'Muy débil',
  [PASSWORD_STRENGTH_WEAK]: 'Débil',
  [PASSWORD_STRENGTH_REASONABLE]: 'Medio',
  [PASSWORD_STRENGTH_STRONG]: 'Fuerte',
  [PASSWORD_STRENGTH_VERY_STRONG]: 'Muy fuerte',
};

const passwordStrengthLabel = (id) => {
  return PASSWORD_STRENGTH_LABELS[id];
};

const passwordStrengthChecker = new taiPasswordStrength.PasswordStrength();

const checkPasswordStrength = (password) => {
  const checkResult = passwordStrengthChecker.check(password);
  return {
    id: checkResult.strengthCode,
  };
};

const generateNewPassword = () =>
  passwordGenerator({
    length: 20,
    numbers: true,
    uppercase: true,
    symbols: true,
    excludeSimilarCharacters: true,
    strict: true,
  });

const PASSWORD_VISIBLE = 'visible';
const PASSWORD_INVISIBLE = 'invisible';

export default function PasswordChangeField({ name }) {
  const [displayPasswordField, setDisplayPasswordField] = useState(false);
  const [passwordFieldVisibility, setPasswordFieldVisibility] =
    useState(PASSWORD_VISIBLE);

  const { touched, errors, isSubmitting, values, setFieldValue } =
    useFormikContext();

  const onGenerateNewPassword = useCallback(() => {
    setFieldValue(name, generateNewPassword(), true);
  }, [name, setFieldValue]);

  const onCancelPasswordChange = useCallback(() => {
    setFieldValue(name, '', true);
  }, [name, setFieldValue]);

  useEffect(() => {
    setDisplayPasswordField(values[name] !== '');
  }, [name, values, setDisplayPasswordField]);

  const onTogglePasswordVisibility = useCallback(() => {
    setPasswordFieldVisibility((previousVisibility) => {
      if (previousVisibility === PASSWORD_VISIBLE) {
        return PASSWORD_INVISIBLE;
      }
      return PASSWORD_VISIBLE;
    });
  }, []);

  const hasError = name in touched && name in errors;
  const interactive = !isSubmitting;
  const currentPassword = values[name];
  const hasPasswordWritten = currentPassword !== '';
  const passwordStrengthResult = checkPasswordStrength(currentPassword);
  const PasswordFieldComponent =
    passwordFieldVisibility === PASSWORD_VISIBLE ? TextField : PasswordField;

  return (
    <div className="flex items-center w-full">
      <div className="flex flex-col space-y-4 lg:space-y-6 w-full">
        <div className="inline-flex items-center">
          <button
            className={clsx(
              'rounded-full focus:outline-none py-1.5 px-6 leading-6 font-normal inline-flex items-center justify-center border border-black border-opacity-25',
              interactive &&
                'focus:ring-4 focus:bg-white hover:bg-gray-50 hover:border-opacity-40 hover:text-gray-900',
              interactive &&
                !hasError &&
                'focus:ring-gray-200 focus:border-gray-900 placeholder:text-gray-400 focus:text-gray-900'
            )}
            onClick={onGenerateNewPassword}
            type="button"
          >
            Establecer una nueva contraseña
          </button>
        </div>
        {displayPasswordField && (
          <div className="flex items-start space-x-4 w-full">
            <div className="flex flex-col rounded flex-1">
              <PasswordFieldComponent
                name={name}
                className="font-mono font-medium text-lg"
                roundClassName={clsx(
                  !hasPasswordWritten && 'rounded',
                  hasPasswordWritten && 'rounded-t'
                )}
                borderClassName={clsx(
                  !hasPasswordWritten &&
                    'border border-black border-opacity-25',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_VERY_WEAK &&
                    'border-red-400',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_WEAK &&
                    'border-red-200',
                  hasPasswordWritten &&
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_REASONABLE &&
                    'border-yellow-500',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_STRONG &&
                    'border-green-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_VERY_STRONG &&
                    'border-green-600'
                )}
                interactiveNoErrorClassName={clsx(
                  'placeholder:text-gray-400 focus:ring-opacity-50',
                  !hasPasswordWritten &&
                    'focus:ring-green-200 focus:border-green-600 placeholder:text-gray-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_VERY_WEAK &&
                    'focus:ring-red-100 focus:border-red-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_WEAK &&
                    'focus:ring-red-100 focus:border-red-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_REASONABLE &&
                    'focus:ring-yellow-100 focus:border-yellow-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id === PASSWORD_STRENGTH_STRONG &&
                    'focus:ring-green-50 focus:border-green-600',
                  hasPasswordWritten &&
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_VERY_STRONG &&
                    'focus:ring-green-100 focus:border-green-600'
                )}
              />
              {hasPasswordWritten && (
                <div
                  className={clsx(
                    'font-semibold border-l border-b border-r px-2 py-1 leading-2 rounded-b',
                    passwordStrengthResult.id === PASSWORD_STRENGTH_VERY_WEAK &&
                      'bg-red-100 border-red-400',
                    passwordStrengthResult.id === PASSWORD_STRENGTH_WEAK &&
                      'bg-red-50 border-red-200',
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_REASONABLE &&
                      'bg-yellow-100 border-yellow-500',
                    passwordStrengthResult.id === PASSWORD_STRENGTH_STRONG &&
                      'bg-green-50 border-green-500',
                    passwordStrengthResult.id ===
                      PASSWORD_STRENGTH_VERY_STRONG &&
                      'bg-green-100 border-green-500'
                  )}
                >
                  {passwordStrengthLabel(passwordStrengthResult.id)}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-4 h-11">
              <button
                className="inline-flex items-center space-x-2 px-6 py-1 leading-6 font-normal rounded-full ring-1 border border-transparent ring-black ring-opacity-20 hover:ring-opacity-40 shadow text-green-500 hover:text-green-600 focus:ring-4 focus:ring-opacity-10 focus:border-gray-500 focus:text-green-600"
                onClick={onTogglePasswordVisibility}
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {passwordFieldVisibility === PASSWORD_VISIBLE ? (
                    <>
                      <path
                        fillRule="evenodd"
                        d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                        clipRule="evenodd"
                      />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </>
                  ) : (
                    <>
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </>
                  )}
                </svg>
                <div className="font-medium">
                  {passwordFieldVisibility === PASSWORD_VISIBLE
                    ? 'Ocultar'
                    : 'Mostrar'}
                </div>
              </button>
              <button
                className="inline-flex items-center space-x-2 px-6 py-1 leading-6 font-normal rounded-full ring-1 border border-transparent ring-black ring-opacity-20 hover:ring-opacity-40 shadow text-green-500 hover:text-green-600 focus:ring-4 focus:ring-opacity-10 focus:border-gray-500 focus:text-green-600"
                onClick={onCancelPasswordChange}
                type="button"
              >
                <div className="font-medium">Cancelar</div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PasswordChangeField.defaultProps = {
  name: 'password',
};

PasswordChangeField.propTypes = {
  name: PropTypes.string,
};
