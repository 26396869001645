import { Fragment } from 'react';
import Head from 'next/head';
import { SWRConfig } from 'swr';
import { ApolloProvider } from '@apollo/client';
import { Provider as NextAuthProvider } from 'next-auth/client';
import { ShoppingCartProvider } from '@/components/ShoppingCart/';
import StickyContainer from '@/components/Sticky/StickyContainer';
import { ToasterProvider } from '@/components/Toaster';
import BasicLayout from '@/layouts/BasicLayout';
import { useApollo } from '@/apollo/client';
import countries from 'i18n-iso-countries';
import countriesSpanishLocale from 'i18n-iso-countries/langs/es.json';

import '@/styles/globals.css';

countries.registerLocale(countriesSpanishLocale);

export default function PartnersApp({
  Component,
  pageProps: { session, initialApolloState, ...pageProps },
  context,
}) {
  const apolloClient = useApollo(initialApolloState, context);

  const Layout = Component.layoutProps?.Layout || BasicLayout;
  const layoutProps = Component.layoutProps || {};

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Asociación Iberoamericana de Scrum</title>
        <meta name="robots" content="noindex" />
        <link href="https://rsms.me/inter/inter.css" rel="stylesheet" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.paymentez.com/checkout/1.0.1/paymentez-checkout.min.css"
          media="all"
        />
      </Head>
      <NextAuthProvider session={session}>
        <ApolloProvider client={apolloClient}>
          <ShoppingCartProvider>
            <StickyContainer>
              <SWRConfig
                value={{
                  fetcher: (resource, init) =>
                    fetch(resource, init).then((res) => res.json()),
                }}
              >
                <Layout {...layoutProps}>
                  <Component {...pageProps} />
                </Layout>
              </SWRConfig>
            </StickyContainer>
            <ToasterProvider />
          </ShoppingCartProvider>
        </ApolloProvider>
      </NextAuthProvider>
    </Fragment>
  );
}
