import { serialize, parse } from 'cookie';

export const COOKIE_MAX_AGE = 60 * 60 * 8; // 8 hours

export function setCookie(cookieName, val, res) {
  if (res === null || res === undefined) {
    return null;
  }

  const cookie = serialize(cookieName, val, {
    maxAge: COOKIE_MAX_AGE,
    expires: new Date(Date.now() + COOKIE_MAX_AGE * 1000),
    httpOnly: true,
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });

  res.setHeader('Set-Cookie', cookie);
}

export function parseCookies(req) {
  if (req === null || req === undefined) {
    return null;
  }

  // For API Routes we don't need to parse the cookies.
  if (req.cookies) {
    return req.cookies;
  }

  // For pages we do need to parse the cookies.
  const cookie = req.headers?.cookie;

  return parse(cookie || '');
}

export function getCookie(cookieName, req) {
  const cookies = parseCookies(req);

  if (cookies === null || cookies === undefined) {
    return null;
  }

  return Object.prototype.hasOwnProperty.call(cookies, cookieName)
    ? cookies[cookieName]
    : null;
}
