import PropTypes from 'prop-types';
import { useField } from 'formik';

export default function CheckBox({ name, children }) {
  const [field] = useField({ name, type: 'checkbox' });

  return (
    <label
      className="flex items-center space-x-2 cursor-pointer"
      htmlFor={`${name}Id`}
    >
      <input
        type="checkbox"
        id={`${name}Id`}
        className="text-green-500 bg-gray-100 border-transparent focus:ring-4 focus:ring-green-200 focus:ring-offset-0 rounded focus:bg-white w-5 h-5 focus:border-green-500"
        {...field}
      />
      <div>{children}</div>
    </label>
  );
}

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
