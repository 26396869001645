import clsx from 'clsx';
import Container from '@/components/Container';
import Link from '@/components/Link';
import Sticky from '@/components/Sticky';
import MainMenu from '@/components/MainMenu';
import CurrentUserMenu from '@/components/CurrentUserMenu';
import ShoppingCartMemu from '@/components/ShoppingCartMenu';

export default function Header() {
  return (
    <div className="hidden lg:block sticky top-0 z-50">
      <Sticky topOffset={10}>
        {({ isSticky, style }) => (
          <div className="relative z-50 lg:sticky lg:top-0" style={style}>
            <div
              className={clsx(
                'lg:h-20 flex items-center transition-all ease-in-out duration-300 bg-white',
                isSticky && 'shadow-xl'
              )}
              style={{
                backdropFilter: 'blur(20px) saturate(120%)',
                backgroundColor: 'rgba(255, 255, 255, 0.65)',
              }}
            >
              <Container className="py-6 lg:py-0">
                <div className="flex items-center w-full">
                  <div className="flex items-center space-x-2 select-none">
                    <a
                      className="inline-flex"
                      href="https://www.aibes.org/"
                      title="Ir al sitio principal de la Asociación Iberoamericana de Scrum"
                    >
                      <div className="inline-flex items-center space-x-2">
                        <div className="aibes-logo base">
                          <span className="primary" />
                          <span className="secondary" />
                          <span className="tertiary" />
                        </div>
                        <div className="font-extrabold uppercase text-2xl tracking-wide select-none">
                          AIBE
                          <span className="text-green-500">S</span>
                        </div>
                      </div>
                    </a>
                    <div className="text-2xl font-extralight text-gray-900">
                      /
                    </div>
                    <Link
                      className="inline-flex text-lg text-green-500 hover:text-green-600 font-semibold tracking-tight"
                      href="/"
                      title="Ir al inicio"
                    >
                      Socios
                    </Link>
                  </div>
                  <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center space-x-6">
                      <MainMenu />
                      <ShoppingCartMemu />
                    </div>
                    <div className="w-px h-6 bg-black bg-opacity-10 mx-6" />
                    <CurrentUserMenu />
                  </div>
                </div>
              </Container>
            </div>
            <div className="w-2/4 h-px bg-gradient-to-r from-black to-transparent opacity-20" />
          </div>
        )}
      </Sticky>
    </div>
  );
}
