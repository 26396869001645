import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  className: null,
  maxWidth: 'max-w-screen-xl lg:max-w-screen-2xl',
};

const propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default function Container({ className, maxWidth, children, ...props }) {
  return (
    <div
      className={clsx(
        'w-full container mx-auto px-10 xl:px-16',
        maxWidth,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

Container.defaultProps = defaultProps;
Container.propTypes = propTypes;
