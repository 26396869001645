import { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Tooltip from '@/components/Tooltip';
import Form from '@/components/Form';
import clsx from 'clsx';

function QuantitySelectorButton({
  buttonTitle,
  className,
  disabled,
  children: svgIcon,
  large,
  ...rest
}) {
  return (
    <Tooltip tooltip={buttonTitle}>
      <button
        className={clsx(
          'flex items-center justify-center focus:outline-none bg-gray-50 hover:bg-white text-gray-600 hover:text-gray-800 focus:ring-4 focus:ring-gray-900 focus:ring-opacity-10 focus:bg-white focus:text-gray-800 border-black border-opacity-20 focus:border-black focus:border-opacity-20 hover:border-black hover:border-opacity-20',
          !large && 'w-10 lg:w-14',
          large && 'w-12 lg:w-16',
          disabled && 'pointer-events-none opacity-70',
          className
        )}
        type="button"
        disabled={disabled}
        {...rest}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 lg:h-6 lg:w-6"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          {svgIcon}
        </svg>
      </button>
    </Tooltip>
  );
}

QuantitySelectorButton.defaultProps = {
  className: null,
  disabled: false,
  large: false,
};

QuantitySelectorButton.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  large: PropTypes.bool,
};

function QuantitySelectorDecreaseButton(props) {
  return (
    <QuantitySelectorButton {...props}>
      <path
        fillRule="evenodd"
        d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </QuantitySelectorButton>
  );
}

function QuantitySelectorIncreaseButton(props) {
  return (
    <QuantitySelectorButton {...props}>
      <path
        fillRule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </QuantitySelectorButton>
  );
}

const QTY_MIN_VALUE = 1;
const QTY_MAX_VALUE = 10000;

const quantityFieldValidationSchema = Yup.object({
  quantity: Yup.number('Sólo se permiten número enteros positivos.')
    .integer('Sólo se permiten número enteros positivos.')
    .min(
      QTY_MIN_VALUE,
      `Debe especificar una cantidad no menor a ${QTY_MIN_VALUE}.`
    )
    .max(
      QTY_MAX_VALUE,
      `Debe especificar una cantidad no mayor a ${QTY_MAX_VALUE}`
    )
    .required('Debe especificar una cantidad.')
    .test('integer', 'Sólo se permiten número enteros positivos.', (number) => {
      return number && number.toString().indexOf('.') === -1;
    }),
});

function QuantitySelectorForm({
  values: { quantity },
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  submitForm,
  disable,
  large,
}) {
  const onDecreaseQuantity = useCallback(async () => {
    const nextQuantity = quantity - 1;
    if (nextQuantity >= 1) {
      await setFieldValue('quantity', nextQuantity, true);
      submitForm();
    }
  }, [setFieldValue, submitForm, quantity]);

  const onIncreaseQuantity = useCallback(async () => {
    await setFieldValue('quantity', quantity + 1, true);
    submitForm();
  }, [setFieldValue, submitForm, quantity]);

  const fieldHasError = 'quantity' in errors && !!errors.quantity;

  const currentQuantityLength = quantity.toString().length;
  const isMoreLessThan3Digits = currentQuantityLength <= 3;
  const isMoreThan3Digits =
    currentQuantityLength >= 4 && currentQuantityLength <= 5;
  const isMoreThan4Digits =
    currentQuantityLength >= 6 && currentQuantityLength <= 7;
  const isMoreThan5Digits = currentQuantityLength >= 8;

  return (
    <div className="relative">
      <div
        className={clsx(
          'inline-flex items-stretch rounded-full bg-white shadow',
          disable && 'pointer-events-none opacity-70'
        )}
      >
        <QuantitySelectorDecreaseButton
          className="rounded-l-full border-t border-b border-l"
          buttonTitle="Disminuir cantidad"
          onClick={onDecreaseQuantity}
          large={large}
        />
        <div className="flex items-center justify-center flex-1 relative z-20">
          <input
            type="number"
            name="quantity"
            className={clsx(
              'border text-center bg-transparent hover:bg-white focus:ring-4 focus:ring-opacity-20 focus:outline-none w-full text-base leading-5 font-medium p-0',
              !fieldHasError &&
                'focus:ring-green-500 border-black border-opacity-20 focus:border-black focus:border-opacity-40',
              fieldHasError &&
                'focus:ring-red-500 border-red-500 focus:border-red-500',
              isMoreLessThan3Digits && 'w-12',
              isMoreThan3Digits && 'w-14',
              isMoreThan4Digits && 'w-16',
              isMoreThan5Digits && 'w-20',
              disable && 'pointer-events-none',
              !large && 'h-7 lg:h-11',
              large && 'h-10 lg:h-12'
            )}
            disabled={disable}
            min={QTY_MIN_VALUE}
            max={QTY_MAX_VALUE}
            onChange={handleChange}
            onBlur={handleBlur}
            value={quantity}
            step={1}
          />
        </div>
        <QuantitySelectorIncreaseButton
          className="rounded-r-full border-t border-b border-r"
          buttonTitle="Aumentar cantidad"
          onClick={onIncreaseQuantity}
          large={large}
        />
      </div>
      {fieldHasError && (
        <div
          className="text-sm absolute bg-red-500 text-white font-medium rounded px-2 py-1 whitespace-nowrap mt-2"
          role="alert"
        >
          {errors.quantity}
        </div>
      )}
    </div>
  );
}

export default function QuantitySelector({
  initialQuantity,
  onQuantityUpdated,
  disable,
  large,
}) {
  return (
    <Form
      initialValues={{ quantity: initialQuantity }}
      validationSchema={quantityFieldValidationSchema}
      onSubmit={(values) => onQuantityUpdated(values.quantity)}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(props) => (
        <QuantitySelectorForm {...props} disable={disable} large={large} />
      )}
    </Form>
  );
}

QuantitySelector.defaultProps = {
  initialQuantity: 1,
  disable: false,
  onQuantityUpdated: () => null,
  large: false,
};

QuantitySelector.propTypes = {
  initialQuantity: PropTypes.number,
  disable: PropTypes.bool,
  onQuantityUpdated: PropTypes.func,
  large: PropTypes.bool,
};
