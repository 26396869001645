import PropTypes from 'prop-types';
import { useFormikContext, ErrorMessage } from 'formik';
import FormErrorMessage from './FormErrorMessage';
import Tooltip from '@/components/Tooltip';
import clsx from 'clsx';

const defaultProps = {
  label: null,
  help: null,
  notRequired: false,
  required: false,
  disabled: false,
  wrapInLabel: true,
};

const propTypes = {
  label: PropTypes.string,
  help: PropTypes.string,
  notRequired: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  wrapInLabel: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function FormField({
  label,
  help,
  notRequired,
  required,
  disabled,
  wrapInLabel,
  fieldName,
  children,
}) {
  const { errors, touched, isSubmitting } = useFormikContext();

  const hasError = fieldName in touched && fieldName in errors;
  const WrappedComponent = wrapInLabel ? 'label' : 'div';
  const LabelComponent = wrapInLabel ? 'div' : 'label';

  return (
    <div
      className={clsx(
        'flex flex-col space-y-2',
        isSubmitting && 'pointer-events-none opacity-70',
        disabled && 'pointer-events-none'
      )}
    >
      <WrappedComponent className="flex flex-col">
        {label && (
          <LabelComponent className="mb-2 flex items-center space-x-2">
            <div className="font-medium">{label}</div>
            {notRequired && !required && (
              <span className="block text-gray-500">(no requerido)</span>
            )}
            {!notRequired && required && (
              <span className="block text-gray-500">(requerido)</span>
            )}
          </LabelComponent>
        )}
        <div className="w-full relative">
          <div
            className={clsx('w-full relative z-10', disabled && 'opacity-50')}
          >
            {children}
          </div>
          {hasError && (
            <div className="absolute top-0 right-0 z-20 flex items-center px-4 mt-3">
              <Tooltip
                tooltip={
                  <ErrorMessage name={fieldName} component="div">
                    {(message) => message}
                  </ErrorMessage>
                }
              >
                <svg
                  className="w-5 h-5 text-red-500 hover:text-red-600 cursor-pointer focus:outline-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </Tooltip>
            </div>
          )}
        </div>
        {help && <div className="text-sm text-gray-600 mt-2">{help}</div>}
      </WrappedComponent>
      <FormErrorMessage name={fieldName} />
    </div>
  );
}

FormField.defaultProps = defaultProps;
FormField.propTypes = propTypes;
