import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  className: '',
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default function FormActions({ className, children }) {
  return (
    <div
      className={clsx(
        'flex items-center space-x-2 lg:space-x-4 w-full justify-end',
        className
      )}
    >
      {children}
    </div>
  );
}

FormActions.defaultProps = defaultProps;
FormActions.propTypes = propTypes;
