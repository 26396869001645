import { createRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TooltipHeadless from './TooltipHeadless';

function renderTooltipDefault(tooltip, addDefaultClassName = true, attrs = {}) {
  return (
    <div tabIndex="-1" {...attrs}>
      <div
        className={clsx(
          addDefaultClassName &&
            'bg-gray-900 text-gray-200 text-sm font-medium rounded-full shadow px-3 py-1 leading-normal transition ease-in-out duration-300 transform'
        )}
      >
        {tooltip}
      </div>
    </div>
  );
}

export default function Tooltip({
  tooltip,
  addDefaultClassName,
  show,
  children,
  renderTooltip,
  ...tippyProps
}) {
  if (show === false) {
    return children;
  }

  const el = createRef(null);

  return (
    <TooltipHeadless
      ref={el}
      render={(attrs) => renderTooltip(tooltip, addDefaultClassName, attrs)}
      {...tippyProps}
    >
      {children}
    </TooltipHeadless>
  );
}

Tooltip.defaultProps = {
  addDefaultClassName: true,
  show: true,
  renderTooltip: renderTooltipDefault,
};

Tooltip.propTypes = {
  tooltip: PropTypes.node.isRequired,
  addDefaultClassName: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
  renderTooltip: PropTypes.func,
};
