import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function SocialLink({ href, title, children }) {
  return (
    <Tooltip tooltip={title}>
      <a
        className="block w-8 text-gray-400 hover:text-green-600"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </Tooltip>
  );
}

SocialLink.propTypes = propTypes;
