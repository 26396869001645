import Container from '@/components/Container';
import Image from 'next/image';
import Link from '@/components/Link';
import Social from '@/components/Social';
import { withAuthenticatedUser } from '@/components/ProtectedPage';
import PaymentezLogo from '@/public/payments/paymentez.png';
import PayPalLogo from '@/public/payments/paypal.svg';

function Footer({ loadingCurrentUser, isAuthenticated }) {
  return (
    <footer className="py-4 bg-white lg:pt-10">
      <Container>
        <div className="grid grid-cols-1 gap-6 py-10 md:grid-cols-12 md:gap-8 lg:gap-10 xl:gap-20">
          <div className="space-y-4 md:col-span-6 lg:col-span-5 lg:space-y-6">
            <div className="flex flex-col space-y-6 lg:space-y-8">
              <div className="aibes-logo base">
                <span className="primary" />
                <span className="secondary" />
                <span className="tertiary" />
              </div>
              <p className="text-xl font-semibold lg:text-2xl lg:font-bold">
                Asociación Iberoamericana de Scrum
              </p>
            </div>
            <div className="text-base leading-relaxed text-gray-700">
              Somos una Organización Internacional Independiente con enfoque
              global y académico, que ofrece certificaciones reconocidas a nivel
              internacional, basadas en el Manifiesto Ágil y los principios de
              Scrum.&nbsp;
              <a
                className="text-green-500 hover:underline hover:text-green-600 font-medium inline-flex items-center space-x-0.5"
                href="https://aibes.org/nosotros/"
              >
                <div>Más información</div>
              </a>
            </div>
            <Social />
          </div>
          <div className="md:col-span-6 lg:col-span-7">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8 lg:gap-10">
              <div className="flex flex-col space-y-4">
                <h3 className="text-lg font-semibold">AIBES</h3>
                <ul className="flex flex-col space-y-2">
                  <li>
                    <a
                      className="text-gray-900 hover:underline hover:text-gray-700"
                      href="//aibes.org"
                    >
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-900 hover:underline hover:text-gray-700"
                      href="https://aibes.org/nosotros/"
                    >
                      Quiénes somos
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-900 hover:underline hover:text-gray-700"
                      href="https://aibes.org/consultoria-y-entrenamiento/"
                    >
                      Consultoría empresarial
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-900 hover:underline hover:text-gray-700"
                      href="https://aibes.org/socios/"
                    >
                      Red de socios
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-900 hover:underline hover:text-gray-700"
                      href="https://aibes.org/contacto/"
                    >
                      Contáctenos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col space-y-6 lg:space-y-8">
                <div className="flex flex-col space-y-4">
                  <h3 className="text-lg font-semibold">Entrenamientos</h3>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/entrenamientos/"
                      >
                        Información de cursos
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/clases/"
                      >
                        Calendario de cursos
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/entrenadores/"
                      >
                        Nuestros entrenadores
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/formacion-de-entrenadores-scrum/"
                      >
                        Formación de entrenadores
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col space-y-4">
                  <h3 className="text-lg font-semibold">Recursos</h3>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://scrumguides.org/"
                      >
                        La guía de Scrum
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/recursos/libros/"
                      >
                        Libros
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col space-y-6 lg:space-y-8">
                <div className="flex flex-col space-y-4">
                  <h3 className="text-lg font-semibold">Entrenadores</h3>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      {loadingCurrentUser ? (
                        <div className="h-5 bg-gray-100 w-28 animate-pulse" />
                      ) : (
                        <Link
                          className="text-gray-900 hover:underline hover:text-gray-700"
                          href={isAuthenticated ? '/perfil' : '/login'}
                        >
                          {isAuthenticated ? 'Mi cuenta' : 'Iniciar sesión'}
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col space-y-4">
                  <h3 className="text-lg font-semibold">Legal</h3>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/tos/"
                      >
                        Términos de servicio
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/legal/privacidad/"
                      >
                        Política de privacidad
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-900 hover:underline hover:text-gray-700"
                        href="https://aibes.org/legal/cookies/"
                      >
                        Política de cookies
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-t border-gray-200" />
        <div className="flex items-center py-10">
          <div className="text-gray-600">
            © 2022 Asociación Iberoamericana de Scrum
          </div>
          <div className="flex items-center justify-end flex-1">
            <div className="flex items-center space-x-4">
              <div className="font-normal text-gray-600">Pagos seguros con</div>
              <div className="flex items-center space-x-4">
                <div className="w-28">
                  <Image
                    alt="Paymentez"
                    src={PaymentezLogo}
                    layout="responsive"
                  />
                </div>
                <div className="w-20">
                  <Image alt="PayPal" src={PayPalLogo} layout="responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default withAuthenticatedUser(Footer, {
  doNotRedirectToLogin: true,
});
