import {
  useContext,
  createContext,
  useReducer,
  useEffect,
  useState,
  useCallback,
} from 'react';
import useCartStorage from './useCartStorage';

export const initialState = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  cartTotal: 0,
  metadata: {},
};

const CartContext = createContext(initialState);

export const createCartIdentifier = (len = 12) =>
  [...Array(len)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

export const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('Expected to be wrapped in a CartProvider');
  }

  return context;
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ITEMS':
      return generateCartState(state, action.payload);

    case 'ADD_ITEM': {
      const items = [...state.items, action.payload];
      return generateCartState(state, items);
    }

    case 'UPDATE_ITEM': {
      const items = state.items.map((item) => {
        if (item.id !== action.id) return item;

        return {
          ...item,
          ...action.payload,
        };
      });

      return generateCartState(state, items);
    }

    case 'REMOVE_ITEM': {
      const items = state.items.filter((i) => i.id !== action.id);

      return generateCartState(state, items);
    }

    case 'EMPTY_CART':
      return initialState;

    case 'CLEAR_CART_META':
      return {
        ...state,
        metadata: {},
      };

    case 'SET_CART_META':
      return {
        ...state,
        metadata: {
          ...action.payload,
        },
      };

    case 'UPDATE_CART_META':
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      };

    default:
      throw new Error('No action specified');
  }
}

const generateCartState = (state = initialState, items) => {
  const totalUniqueItems = calculateUniqueItems(items);
  const isEmpty = totalUniqueItems === 0;

  return {
    ...initialState,
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    cartTotal: calculateTotal(items),
    isEmpty,
  };
};

const calculateItemTotals = (items) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.price * item.quantity,
  }));

const calculateTotal = (items) =>
  items.reduce((total, item) => total + item.quantity * item.price, 0);

const calculateTotalItems = (items) =>
  items.reduce((sum, item) => sum + item.quantity, 0);

const calculateUniqueItems = (items) => items.length;

export const CartProvider = ({
  children,
  id: cartId,
  defaultItems = [],
  onSetItems,
  onItemAdd,
  onItemUpdate,
  onItemRemove,
  onEmptyCart,
  storage = useCartStorage,
  metadata,
}) => {
  const [updatingItems, setUpdatingItems] = useState([]);

  const isItemUpdating = useCallback(
    (id) => {
      return updatingItems.length > 0 && updatingItems.includes(id);
    },
    [updatingItems]
  );

  const startToUpdateItem = useCallback(
    (id) => {
      setUpdatingItems([...(updatingItems || []), id]);
    },
    [updatingItems]
  );

  const finishUpdatingItem = useCallback(
    (id) => {
      setUpdatingItems(updatingItems.filter((idd) => idd !== id));
    },
    [updatingItems]
  );

  const id = cartId ? cartId : createCartIdentifier();

  const [savedCart, saveCart] = storage(
    cartId ? `react-use-cart-${id}` : `react-use-cart`,
    JSON.stringify({
      id,
      ...initialState,
      items: defaultItems,
      metadata,
    })
  );

  const [state, dispatch] = useReducer(reducer, JSON.parse(savedCart));
  useEffect(() => {
    saveCart(JSON.stringify(state));
  }, [state, saveCart]);

  const setItems = (items) => {
    dispatch({
      type: 'SET_ITEMS',
      payload: items.map((item) => ({
        ...item,
        quantity: item.quantity || 1,
      })),
    });

    onSetItems && onSetItems(items);
  };

  const addItem = (
    item,
    quantity = 1,
    apolloClient = null,
    onAddedOrUpdated = () => null
  ) => {
    if (!item.id) {
      throw new Error('You must provide an `id` for items');
    }

    if (quantity <= 0) {
      return;
    }

    const currentItem = state.items.find((i) => i.id === item.id);

    if (!currentItem && !item.hasOwnProperty('price')) {
      throw new Error('You must pass a `price` for new items');
    }

    startToUpdateItem(item.id);

    if (!currentItem) {
      const payload = { ...item, quantity };

      dispatch({ type: 'ADD_ITEM', payload });

      if (onItemAdd) {
        onItemAdd(payload, apolloClient, (newPayload) => {
          onAddedOrUpdated();

          if (newPayload) {
            dispatch({
              type: 'UPDATE_ITEM',
              id: item.id,
              payload: {
                ...payload,
                ...newPayload,
              },
            });
          }

          finishUpdatingItem(item.id);
        });
      } else {
        onAddedOrUpdated();
        finishUpdatingItem(item.id);
      }

      return;
    }

    const payload = {
      ...item,
      quantity: currentItem.quantity + quantity,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      id: item.id,
      payload,
    });

    if (onItemUpdate) {
      onItemUpdate(payload, (itm) => {
        onAddedOrUpdated();

        if (itm) {
          dispatch({
            type: 'UPDATE_ITEM',
            id: item.id,
            payload: {
              ...payload,
              ...itm,
            },
          });
        }
        finishUpdatingItem(item.id);
      });
    } else {
      onAddedOrUpdated();
      finishUpdatingItem(item.id);
    }
  };

  const updateItem = (id, payload, apolloClient = null) => {
    if (!id || !payload) {
      return;
    }

    dispatch({ type: 'UPDATE_ITEM', id, payload });

    onItemUpdate && onItemUpdate(payload, apolloClient);
  };

  const updateItemQuantity = (
    id,
    quantity,
    apolloClient = null,
    onUpdated = () => null
  ) => {
    const currentItem = state.items.find((item) => item.id === id);

    if (!currentItem) {
      throw new Error('No such item to update');
    }

    if (quantity <= 0) {
      startToUpdateItem(id);

      dispatch({ type: 'REMOVE_ITEM', id });

      if (onItemRemove) {
        onItemRemove(currentItem, apolloClient, () => {
          onUpdated();
          finishUpdatingItem(id);
        });
      } else {
        onUpdated();
        finishUpdatingItem(id);
      }

      return;
    }

    startToUpdateItem(id);

    const payload = { ...currentItem, quantity };

    dispatch({
      type: 'UPDATE_ITEM',
      id,
      payload,
    });

    if (onItemUpdate) {
      onItemUpdate(payload, apolloClient, () => {
        onUpdated();
        finishUpdatingItem(id);
      });
    } else {
      onUpdated();
      finishUpdatingItem(id);
    }
  };

  const removeItem = (id, apolloClient = null, onRemoved = () => null) => {
    if (!id) {
      return;
    }

    const currentItem = state.items.find((item) => item.id === id);

    if (!currentItem) {
      throw new Error('No such item to update');
    }

    startToUpdateItem(id);

    dispatch({ type: 'REMOVE_ITEM', id });

    if (onItemRemove) {
      onItemRemove(currentItem, apolloClient, () => {
        onRemoved();
        finishUpdatingItem(id);
      });
    } else {
      onRemoved();
      finishUpdatingItem(id);
    }
  };

  const emptyCart = (apolloClient = null, onFinish = () => null) => {
    onEmptyCart(apolloClient, () => {
      dispatch({
        type: 'EMPTY_CART',
      });

      onFinish();
    });
  };

  const emptyLocalCart = () => {
    dispatch({
      type: 'EMPTY_CART',
    });
  };

  const getItem = (id) => state.items.find((i) => i.id === id);

  const inCart = (id) => state.items.some((i) => i.id === id);

  const clearCartMetadata = () => {
    dispatch({
      type: 'CLEAR_CART_META',
    });
  };

  const setCartMetadata = (metadata) => {
    if (!metadata) return;

    dispatch({
      type: 'SET_CART_META',
      payload: metadata,
    });
  };

  const updateCartMetadata = (metadata) => {
    if (!metadata) return;

    dispatch({
      type: 'UPDATE_CART_META',
      payload: metadata,
    });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        getItem,
        inCart,
        setItems,
        addItem,
        updateItem,
        updateItemQuantity,
        removeItem,
        emptyCart,
        emptyLocalCart,
        clearCartMetadata,
        setCartMetadata,
        updateCartMetadata,
        isItemUpdating,
        finishUpdatingItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
