import Image from 'next/image';
import Link from '@/components/Link';
import { signOut } from 'next-auth/client';
import { Menu } from '@headlessui/react';
import { removeWoocommerceAuthTokenSession } from '@/apollo/client';
import { withAuthenticatedUser } from '@/components/ProtectedPage';
import clsx from 'clsx';

function CurrentUserMenuItem({ href, children }) {
  return (
    <Menu.Item className="px-4 py-2 lg:py-2.5 hover:bg-gray-100 w-full flex items-center justify-start leading-5 text-gray-800 hover:text-gray-900 focus:ring-4 focus:ring-green-500 focus:ring-opacity-20 relative z-10 focus:z-20 border-t border-b border-transparent focus:border-green-400 focus:bg-green-50 lg:font-medium">
      <Link href={href}>{children}</Link>
    </Menu.Item>
  );
}

function CurrentUserMenu({ user, isAuthenticated, loadingCurrentUser }) {
  if (loadingCurrentUser) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center',
          !user?.avatar?.foundAvatar && 'w-14',
          user?.avatar?.foundAvatar && 'w-40'
        )}
      >
        <div className="w-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 30"
            className="fill-current text-gray-400 w-full h-auto"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="60" cy="15" r="9" fillOpacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
      </div>
    );
  }

  const handleLogout = async (options) => {
    await signOut(options);
    removeWoocommerceAuthTokenSession();
  };

  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';

  let fullName = user.name;
  if (fullName === '') {
    fullName = [firstName, lastName].join(' ');
  }

  return (
    <div className="flex items-center">
      {!isAuthenticated && (
        <Link
          className="inline-flex ring-1 ring-gray-400 rounded-full font-medium hover:bg-gray-900 hover:text-gray-100 hover:ring-transparent focus:ring-4 focus:ring-opacity-10 focus:ring-gray-900 focus:bg-gray-900 focus:text-gray-100"
          href="/login"
        >
          <div className="leading-4 px-5 py-2">Iniciar sesión</div>
        </Link>
      )}
      {isAuthenticated && (
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="flex items-center space-x-1.5 focus:outline-none">
            {({ open }) => (
              <>
                <div
                  className={clsx(
                    'w-10 h-10 relative rounded-full ring-4 shadow border',
                    open && 'ring-gray-900 ring-opacity-10 border-gray-600',
                    !open && 'ring-white border-transparent'
                  )}
                >
                  {user?.avatar && user.avatar.foundAvatar ? (
                    <Image
                      alt={fullName}
                      src={user.avatar.url}
                      width={user.avatar.width}
                      height={user.avatar.height}
                      layout="responsive"
                      className="block bg-gray-100 rounded lg:rounded-full"
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex items-center space-x-px">
                  <div
                    className={clsx(
                      'font-medium',
                      user?.avatar?.foundAvatar && 'sr-only'
                    )}
                  >
                    Mi cuenta
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </>
            )}
          </Menu.Button>
          <Menu.Items className="absolute right-0 w-64 mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-lg lg:rounded-xl shadow-lg lg:shadow-xl ring-1 ring-black ring-opacity-10 focus:outline-none">
            <div className="py-2 lg:py-4 divide-y w-full">
              <div className="w-full py-2 px-px">
                <Menu.Item className="px-4 py-2 lg:py-2.5 hover:bg-gray-100 w-full flex items-center justify-start leading-5 text-gray-800 hover:text-gray-900 focus:ring-4 focus:ring-green-500 focus:ring-opacity-20 relative z-10 focus:z-20 border-t border-b border-transparent focus:border-green-400 focus:bg-green-50 lg:font-medium">
                  <Link
                    className="focus:ring-4 focus:ring-opacity-40 flex"
                    href="/perfil"
                  >
                    <div className="space-y-0.5 mx-px">
                      <h4 className="font-semibold leading-5">{fullName}</h4>
                      {user.email && (
                        <div className="text-sm text-gray-500">
                          {user.email}
                        </div>
                      )}
                    </div>
                  </Link>
                </Menu.Item>
              </div>
              <div className="w-full py-4 px-px">
                <CurrentUserMenuItem href="/perfil/cuenta">
                  Editar el perfil
                </CurrentUserMenuItem>
                <CurrentUserMenuItem href="/perfil/facturacion">
                  Facturación
                </CurrentUserMenuItem>
                <CurrentUserMenuItem href="/perfil/ordenes">
                  Pedidos de certificaciones
                </CurrentUserMenuItem>
                <CurrentUserMenuItem href="/perfil/examenes">
                  Exámenes de certificación
                </CurrentUserMenuItem>
              </div>
              <div className="w-full pt-2 lg:pt-4 pb-4 px-px">
                <Menu.Item
                  className="px-4 py-2 lg:py-2.5 hover:bg-gray-100 w-full flex items-center justify-start leading-5 text-gray-800 hover:text-gray-900 focus:ring-4 focus:ring-green-500 focus:ring-opacity-20 relative z-10 focus:z-20 border-t border-b border-transparent focus:border-green-400 focus:bg-green-50 lg:font-medium"
                  onClick={() => handleLogout({ redirect: false })}
                >
                  <button type="button">Cerrar sesión</button>
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Menu>
      )}
    </div>
  );
}

export default withAuthenticatedUser(CurrentUserMenu, {
  doNotRedirectToLogin: true,
});
