import { memo, useEffect } from 'react';
import { useSession } from 'next-auth/client';
import { useRouter } from 'next/router';

function useDefaultUser() {
  return {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
  };
}

export default function withAuthenticatedUser(WrapComponent, options = {}) {
  function WithAutenticatedUser(props) {
    const [session, loading] = useSession({
      ...(options?.authOptions || {}),
    });

    const router = useRouter();

    useEffect(() => {
      if (!(session || loading) && !options?.doNotRedirectToLogin) {
        router.push({
          pathname: '/login',
          query: { redirectTo: router.pathname },
        });
      }
    }, [router, session, loading]);

    let user = useDefaultUser();
    if (session?.user) {
      user = session?.user;
    }

    return (
      <WrapComponent
        loadingCurrentUser={loading}
        isAuthenticated={!!session?.user && !loading}
        user={user}
        {...props}
      />
    );
  }

  return memo(WithAutenticatedUser);
}
