import PropTypes from 'prop-types';
import LoadingIndicator from '@/components/LoadingIndicator';

const defaultProps = {
  inverse: false,
};

const propTypes = {
  inverse: PropTypes.bool,
};

export default function Spinner({ inverse }) {
  return (
    <LoadingIndicator
      color={inverse ? 'text-white' : 'text-green-500'}
      size="w-8"
    />
  );
}

Spinner.defaultProps = defaultProps;
Spinner.propTypes = propTypes;
