import Link from '@/components/Link';

function MainMenuLink({ children, ...props }) {
  return (
    <Link
      className="font-medium text-base text-gray-900 hover:text-green-500"
      {...props}
    >
      {children}
    </Link>
  );
}

export default function MainMenu() {
  return (
    <ul className="flex items-center space-x-4">
      <li>
        <MainMenuLink href="/">Inicio</MainMenuLink>
      </li>
      <li>
        <MainMenuLink href="/certificaciones">Certificaciones</MainMenuLink>
      </li>
      <li>
        <a
          className="font-medium text-base text-gray-900 hover:text-green-500"
          href="https://www.credly.com/organizations/asociacion-iberoamericana-de-scrum/badges"
        >
          Insignias digitales
        </a>
      </li>
      <li>
        <MainMenuLink href="/contacto">Contacto</MainMenuLink>
      </li>
    </ul>
  );
}
