import { withShoppingCart } from '@/components/ShoppingCart';
import Link from '@/components/Link';
import { useCart } from '@/components/ShoppingCart';

function ShoppingCartMemu({ loadingShoppingCart, shoppingCartError }) {
  const cart = useCart();

  const cartMenuItemTitle = loadingShoppingCart
    ? 'Cargando la información del carrito'
    : shoppingCartError
    ? 'No se pudo obtener la información del carrito'
    : 'Ir al carrito de compras';

  return (
    <Link
      className="p-2 inline-flex relative hover:bg-gray-900 rounded-full text-gray-700 hover:text-white focus:ring-4 focus:ring-gray-900 focus:ring-opacity-10"
      title={cartMenuItemTitle}
      href="/carrito"
    >
      <svg
        viewBox="0 0 20 22"
        fill="none"
        className="w-5 h-5"
        stroke="currentColor"
      >
        <path
          d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 5H19"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {loadingShoppingCart && (
        <div className="absolute left-0 bottom-0 text-right h-4 w-4 p-0.5 ml-1 bg-white rounded-full z-10">
          <svg
            className="animate-spin h-3 w-3 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
      {!loadingShoppingCart &&
        (shoppingCartError ? (
          <div className="absolute left-0 bottom-0 text-right mb-1 h-4 bg-white rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-red-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : (
          cart.totalItems > 0 && (
            <div
              className="absolute left-0 bottom-0 text-right -mb-0.5 ring-2 ring-white rounded-full bg-gray-900 text-white font-medium leading-0 h-4 px-1 flex items-center justify-center text-xs"
              style={{ minWidth: '15.9976px' }}
            >
              {cart.totalItems}
            </div>
          )
        ))}
    </Link>
  );
}

export default withShoppingCart(ShoppingCartMemu);
