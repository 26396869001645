import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  spacing: 'space-y-6',
  className: null,
};

const propTypes = {
  spacing: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default function FormContent({ spacing, className, children }) {
  return <div className={clsx(spacing, className)}>{children}</div>;
}

FormContent.defaultProps = defaultProps;
FormContent.propTypes = propTypes;
