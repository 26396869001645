import { v4 as uuid } from 'uuid';
import client from '@/apollo/client';
import ADD_CART_ITEM_MUTATION from '@/graphql/mutations/addCartItem.graphql';
import UPDATE_CART_ITEM_QUANTITIES_MUTATION from '@/graphql/mutations/updateCartItemQuantities.graphql';
import { notifyError } from '../Toaster';

export default function onShoppingCartItemAdd(
  product,
  apolloClient = null,
  onFinish = () => null
) {
  async function onShoppingCartItemAddAsync(product) {
    (apolloClient ?? client)
      .mutate({
        mutation: ADD_CART_ITEM_MUTATION,
        variables: {
          input: {
            clientMutationId: uuid(),
            productId: product.id,
            quantity: product.quantity || 1,
          },
        },
      })
      .then(({ data }) => {
        let payload = {},
          storedQuantity;

        if (data?.addToCart?.cartItem) {
          storedQuantity = data.addToCart.cartItem.quantity;
          payload = { ...payload, key: data.addToCart.cartItem.key };
        }

        // Make sure we have the correct quantity number
        if (!!payload.key && storedQuantity !== product.quantity) {
          (apolloClient ?? client)
            .mutate({
              mutation: UPDATE_CART_ITEM_QUANTITIES_MUTATION,
              variables: {
                input: {
                  clientMutationId: uuid(),
                  items: [
                    {
                      key: payload.key,
                      quantity: product.quantity || 1,
                    },
                  ],
                },
              },
            })
            .then((response) => {
              if (response.data) {
                onFinish(payload);
              } else {
                notifyError(
                  'Ocurrió un error al intentar añadir el producto al carrito. Por favor, inténtelo nuevamente.'
                );
                onFinish(null);
              }
            });
        } else {
          onFinish(payload);
        }
      })
      .catch(() => {
        notifyError(
          'Ocurrió un error al intentar añadir el producto al carrito. Por favor, inténtelo nuevamente.'
        );

        onFinish(null);
      });
  }

  onShoppingCartItemAddAsync(product);
}
