import PropTypes from 'prop-types';

const defaultProps = {};

const propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FormFields({ children }) {
  return <div className="space-y-4 lg:space-y-6">{children}</div>;
}

FormFields.defaultProps = defaultProps;
FormFields.propTypes = propTypes;
