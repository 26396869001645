import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Input from './Input';
import clsx from 'clsx';

export default function EntryField({
  name,
  className,
  roundClassName,
  borderClassName,
  interactiveNoErrorClassName,
  ...inputProps
}) {
  const { touched, errors, isSubmitting } = useFormikContext();

  const hasError = name in touched && name in errors;
  const interactive = !isSubmitting;

  return (
    <Input
      name={name}
      className={clsx(
        className,
        roundClassName,
        borderClassName,
        'focus:outline-none h-11 px-4 font-normal w-full placeholder-gray-400',
        !hasError && 'bg-white shadow-sm',
        hasError && 'bg-red-50',
        interactive && 'focus:ring-4 focus:bg-white',
        interactive && !hasError && interactiveNoErrorClassName,
        interactive &&
          hasError &&
          'focus:ring-red-100 focus:border-red-500 placeholder:text-red-400',
        isSubmitting && 'pointer-events-none opacity-50 ring-0 outline-none'
      )}
      {...inputProps}
    />
  );
}

EntryField.defaultProps = {
  className: null,
  roundClassName: 'rounded',
  borderClassName: 'border border-black border-opacity-25',
  interactiveNoErrorClassName:
    'focus:ring-green-200 focus:border-green-600 placeholder:text-gray-400',
};

EntryField.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  roundClassName: PropTypes.string,
  borderClassName: PropTypes.string,
  interactiveNoErrorClassName: PropTypes.string,
};
