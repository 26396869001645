import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CartProvider } from './ShoppingCartContext';
import useCart from './useCart';
import onShoppingCartItemAdd from './onShoppingCartItemAdd';
import onShoppingCartItemUpdate from './onShoppingCartItemUpdate';
import onShoppingCartItemRemove from './onShoppingCartItemRemove';
import onShoppingCartEmpty from './onShoppingCartEmpty';
import withShoppingCart from './withShoppingCart';

let shoppingCartInitialized = false;

function ShoppingCartContainer({ shoppingCart, children }) {
  const { setItems } = useCart();

  useEffect(() => {
    if (
      !shoppingCartInitialized &&
      !!shoppingCart &&
      shoppingCart.contents.itemCount > 0
    ) {
      setItems(
        shoppingCart.contents.nodes.map(
          ({ key, quantity, product: { node: product } }) => ({
            key,
            id: product.productId,
            name: product.name,
            price: product.price,
            quantity,
            product,
          })
        )
      );
      shoppingCartInitialized = true;
    }
  }, [setItems, shoppingCart]);

  return children;
}

const ShoppingCartProviderRenderer = memo(
  withShoppingCart(ShoppingCartContainer)
);

export default function ShoppingCartProvider({ children }) {
  return (
    <CartProvider
      onItemAdd={onShoppingCartItemAdd}
      onItemUpdate={onShoppingCartItemUpdate}
      onItemRemove={onShoppingCartItemRemove}
      onEmptyCart={onShoppingCartEmpty}
    >
      <ShoppingCartProviderRenderer>{children}</ShoppingCartProviderRenderer>
    </CartProvider>
  );
}

ShoppingCartProvider.defaultProps = {
  children: PropTypes.node.isRequired,
};
