import { memo } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

function BasicLayout({ children }) {
  return (
    <div className="flex flex-1 max-w-full min-h-screen">
      <Script
        src="https://cdn.paymentez.com/checkout/1.0.1/paymentez-checkout.min.js"
        charSet="utf-8"
      />
      <div className="flex flex-col w-full">
        <Header />
        <div
          className="flex flex-1 bg-gray-100 w-full relative z-10"
          role="main"
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(BasicLayout);
