import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const defaultProps = {};

const propTypes = {
  name: PropTypes.string.isRequired,
};

export default function FormErrorMessage({ name }) {
  return (
    <ErrorMessage name={name} component="div">
      {(message) => (
        <div className="text-red-600 text-sm" role="alert">
          {message}
        </div>
      )}
    </ErrorMessage>
  );
}

FormErrorMessage.defaultProps = defaultProps;
FormErrorMessage.propTypes = propTypes;
