import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import clsx from 'clsx';

export default function SelectField({ name, options }) {
  const { touched, errors, isSubmitting } = useFormikContext();

  const hasError = name in touched && name in errors;
  const interactive = !isSubmitting;

  return (
    <Field
      as="select"
      name={name}
      className={clsx(
        'rounded focus:outline-none h-11 px-4 font-normal w-full border border-black border-opacity-25',
        !hasError && 'bg-white shadow-sm',
        hasError && 'bg-red-50',
        interactive && 'focus:ring-4 focus:bg-white',
        interactive &&
          !hasError &&
          'focus:ring-green-200 focus:border-green-600',
        interactive && hasError && 'focus:ring-red-100 focus:border-red-500',
        isSubmitting && 'pointer-events-none opacity-50'
      )}
    >
      {Object.keys(options).map((country) => (
        <option key={country} value={country}>
          {options[country]}
        </option>
      ))}
    </Field>
  );
}

SelectField.defaultProps = {
  options: [],
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
};
