import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { setCookie, getCookie } from './cookies';

const ssrMode = typeof window === 'undefined';

export const TOKEN_NAME = 'token';
export const SESSION_NAME = 'aibesorg-session';
export const WC_SESSION_HEADER = 'woocommerce-session';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  resultCaching: false,
});

/**
 * The credentials: 'include' allows cookies to be automatically sent
 * along with the request 'include' because backend is on another domain.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#cookie
 */
const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_WP_GRAPHQL_URI,
  useGETForQueries: false,
});

export function getWoocommerceAuthTokenSession(req) {
  let tokenSession = {
    token: null,
    session: null,
  };

  if (ssrMode) {
    tokenSession.token = getCookie(TOKEN_NAME, req);
    tokenSession.session = getCookie(SESSION_NAME, req);
  } else {
    tokenSession.token = localStorage.getItem(TOKEN_NAME);
    tokenSession.session = localStorage.getItem(SESSION_NAME);
  }

  return tokenSession;
}

export function setWoocommerceAuthTokenSession(req, session) {
  if (ssrMode) {
    setCookie(SESSION_NAME, session, req);
  } else {
    localStorage.setItem(SESSION_NAME, session);
  }
}

export function removeWoocommerceAuthTokenSession(req) {
  if (ssrMode) {
    removeCookie(SESSION_NAME, req);
  } else {
    localStorage.removeItem(SESSION_NAME);
  }
}

function getWoocommerceAuthSessionMiddleware(ctx = null, accessToken = null) {
  return new ApolloLink((operation, forward) => {
    const { token, session } = getWoocommerceAuthTokenSession(ctx?.req);

    let authToken = token;
    if (accessToken) {
      authToken = accessToken;
    }

    if (session !== null) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: authToken ? `Bearer ${authToken}` : '',
          [WC_SESSION_HEADER]: `Session ${session}`,
        },
      }));
    }

    return forward(operation);
  });
}

function getWoocommerceAuthSessionAfterware(ctx = null) {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const { session: localSessionName } = getWoocommerceAuthTokenSession(
        ctx?.req
      );
      const {
        response: { headers },
      } = operation.getContext();

      const session = headers.get(WC_SESSION_HEADER);

      if (session !== null && session !== undefined) {
        if ('false' === session) {
          removeWoocommerceAuthTokenSession(ctx?.req);
        } else if (localSessionName !== session) {
          setWoocommerceAuthTokenSession(ctx?.res, session);
        }
      }

      return response;
    });
  });
}

export function createApolloClient(ctx = null, accessToken = null) {
  return new ApolloClient({
    ssrMode,
    link: ApolloLink.from([
      getWoocommerceAuthSessionMiddleware(ctx, accessToken),
      getWoocommerceAuthSessionAfterware(ctx),
      httpLink,
    ]),
    cache,
    defaultOptions,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
}

let apolloClient;

export function initializeApollo(initialState = null, ctx = null) {
  const _apolloClient =
    apolloClient ?? createApolloClient(ctx, initialState?.accessToken || null);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({
      ...existingCache,
      ...initialState,
    });
  }

  if (ssrMode) {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(initialState = {}, ctx = null) {
  const store = useMemo(() => {
    return initializeApollo(initialState, ctx);
  }, [initialState]);
  return store;
}

const defaultClient = initializeApollo();

export default defaultClient;
