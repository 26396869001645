import PropTypes from 'prop-types';

export default function QuantitySelectorStatic({ quantity }) {
  return (
    <div className="md:col-span-2 md:text-right">
      <div className="inline-flex items-center space-x-1 bg-white shadow ring-1 ring-black ring-opacity-10 text-sm rounded px-2 py-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <div className="leading-4">{quantity}</div>
      </div>
    </div>
  );
}

QuantitySelectorStatic.defaultProps = {
  quantity: 0,
};

QuantitySelectorStatic.propTypes = {
  quantity: PropTypes.number,
};
