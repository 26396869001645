import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import clsx from 'clsx';

export default function TextAreaField({ name, rows, ...rest }) {
  const { touched, errors, isSubmitting } = useFormikContext();

  const hasError = name in touched && name in errors;
  const interactive = !isSubmitting;

  return (
    <Field
      as="textarea"
      className={clsx(
        'rounded focus:outline-none px-4 font-normal w-full border border-black border-opacity-25 placeholder-gray-400',
        !hasError && 'bg-white shadow-sm',
        hasError && 'bg-red-50',
        interactive && 'focus:ring-4 focus:bg-white',
        interactive &&
          !hasError &&
          'focus:ring-green-200 focus:border-green-600 placeholder:text-gray-400',
        interactive &&
          hasError &&
          'focus:ring-red-100 focus:border-red-500 placeholder:text-red-400',
        isSubmitting && 'pointer-events-none opacity-50'
      )}
      name={name}
      rows={rows}
      {...rest}
    />
  );
}

TextAreaField.defaultProps = {
  rows: 4,
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
};
