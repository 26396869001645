import PropTypes from 'prop-types';
import clsx from 'clsx';
import Spinner from '@/components/Spinner';
import { useFormikContext } from 'formik';

const defaultProps = {
  disabled: false,
};

const propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default function FormSubmit({ disabled, children }) {
  const { isValid, isSubmitting } = useFormikContext();

  const interactive = !isSubmitting;

  return (
    <button
      className={clsx(
        'rounded-full inline-flex relative items-center justify-center bg-green-500 text-white font-medium px-6 py-2 leading-5 focus:outline-none',
        interactive &&
          'hover:bg-green-600 focus:bg-green-600 focus:ring-4 focus:ring-green-200',
        (isSubmitting || disabled || !isValid) &&
          'pointer-events-none opacity-50'
      )}
      disabled={isSubmitting || disabled || !isValid}
      type="submit"
    >
      {isSubmitting && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner inverse />
        </div>
      )}
      <div className={clsx(isSubmitting && 'invisible')}>{children}</div>
    </button>
  );
}

FormSubmit.defaultProps = defaultProps;
FormSubmit.propTypes = propTypes;
