import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { notifyError } from '@/components/Toaster';
import { withAuthenticatedUser } from '@/components/ProtectedPage';
import { createApolloClient } from '@/apollo/client';
import GET_CART_QUERY from '@/graphql/queries/getCart.graphql';

export default function withShoppingCart(WrappedComponent) {
  function WithShoppingCart({
    loadingCurrentUser,
    isAuthenticated,
    user,
    orderId,
    ...props
  }) {
    const [cartState, setCartState] = useState({
      loadingShoppingCart: true,
      shoppingCart: null,
      shoppingCartError: null,
      refetchingShoppingCartInfo: false,
    });

    useEffect(() => {
      if (loadingCurrentUser === false) {
        const apolloClient = createApolloClient(null, user?.accessToken);

        apolloClient
          .query({
            query: GET_CART_QUERY,
          })
          .then((response) => {
            setCartState({
              loadingShoppingCart: response.loading,
              shoppingCart: response.data?.cart || null,
              shoppingCartError: response.error ?? null,
              refetchingShoppingCartInfo: false,
            });
          })
          .catch(() =>
            notifyError(
              'Ocurrió un error al intentar cargar el contenido del carrito de compras. Por favor, inténtelo nuevamente.'
            )
          );
      }
    }, [loadingCurrentUser, user]);

    const refetchShoppingCart = useCallback(async () => {
      if (loadingCurrentUser) {
        return;
      }

      setCartState((prevState) => ({
        ...prevState,
        refetchingShoppingCartInfo: true,
      }));

      const apolloClient = createApolloClient(null, user?.accessToken);

      // TODO: check if we receive an error
      const response = await apolloClient.query({
        query: GET_CART_QUERY,
      });

      setCartState({
        loadingShoppingCart: response.loading,
        shoppingCart: response.data?.cart || null,
        shoppingCartError: response.error ?? null,
        refetchingShoppingCartInfo: false,
      });
    }, [loadingCurrentUser, user]);

    const refetchShoppingCartInfo = useCallback(
      async (done = () => null) => {
        await refetchShoppingCart();
        done();
      },
      [refetchShoppingCart]
    );

    return (
      <WrappedComponent
        loadingShoppingCart={cartState.loadingShoppingCart}
        shoppingCartError={cartState.shoppingCartError}
        shoppingCart={cartState.shoppingCart}
        refetchShoppingCartInfo={refetchShoppingCartInfo}
        refetchingShoppingCartInfo={cartState.refetchingShoppingCartInfo}
        loadingCurrentUser={loadingCurrentUser}
        isAuthenticated={isAuthenticated}
        user={user}
        orderId={orderId}
        {...props}
      />
    );
  }

  WithShoppingCart.defaultProps = {
    orderId: null,
  };

  WithShoppingCart.propTypes = {
    orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  return withAuthenticatedUser(WithShoppingCart, {
    doNotRedirectToLogin: true,
  });
}
