import PropTypes from 'prop-types';
import { Formik } from 'formik';
import clsx from 'clsx';

const defaultProps = {
  className: null,
  handleSubmit: () => null,
  initialValues: {},
};

const propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  children: PropTypes.func.isRequired,
};

export default function Form({
  className,
  handleSubmit,
  initialValues,
  children,
  ...otherProps
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, props) => handleSubmit({ values, ...props })}
      {...otherProps}
    >
      {({ handleSubmit, ...rest }) => (
        <form className={clsx(className)} onSubmit={handleSubmit}>
          {children(rest)}
        </form>
      )}
    </Formik>
  );
}

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;
