import PropTypes from 'prop-types';
import { Field } from 'formik';

const defaultProps = {
  className: null,
};

const propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default function Input({ type, className, ...inputProps }) {
  return <Field type={type} className={className} {...inputProps} />;
}

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;
