import { v4 as uuid } from 'uuid';
import client from '@/apollo/client';
import EMPTY_CART_MUTATION from '@/graphql/mutations/emptyCart.graphql';

export default function onShoppingCartEmpty(
  apolloClient = null,
  onFinish = () => null
) {
  async function onShoppingCartEmptyAsync(onFinish) {
    (apolloClient ?? client)
      .mutate({
        mutation: EMPTY_CART_MUTATION,
        variables: {
          input: {
            clientMutationId: uuid(),
          },
        },
      })
      .then(() => {
        onFinish();
      })
      .catch((error) => onFinish(error));
  }

  onShoppingCartEmptyAsync(onFinish);
}
