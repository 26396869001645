import PropTypes from 'prop-types';
import NextLink from 'next/link';

const defaultProps = {};

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function Link({ href, children, ...props }) {
  return (
    <NextLink href={href}>
      <a {...props}>{children}</a>
    </NextLink>
  );
}

Link.defaultProps = defaultProps;
Link.propTypes = propTypes;
