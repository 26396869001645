import SelectField from './SelectField';
import formatCountryName from '@/lib/formatCountryName';
import getAllCountryCodes from '@/lib/getAllCountryCodes';

export default function CountrySelectField(props) {
  const countryOptions = Object.fromEntries(
    getAllCountryCodes().map((country) => [country, formatCountryName(country)])
  );
  return <SelectField options={countryOptions} {...props} />;
}
