import { v4 as uuid } from 'uuid';
import client from '@/apollo/client';
import UPDATE_CART_ITEM_QUANTITIES_MUTATION from '@/graphql/mutations/updateCartItemQuantities.graphql';
import { notifyError } from '../Toaster';

export default function onShoppingCartItemRemove(
  product,
  apolloClient = null,
  onFinish = () => null
) {
  async function onShoppingCartItemRemoveAsync(product) {
    (apolloClient ?? client)
      .mutate({
        mutation: UPDATE_CART_ITEM_QUANTITIES_MUTATION,
        variables: {
          input: {
            clientMutationId: uuid(),
            items: [
              {
                key: product.key,
                quantity: 0,
              },
            ],
          },
        },
      })
      .then(() => {
        onFinish();
      })
      .catch((error) => {
        notifyError(
          'Ocurrió un error al intentar eliminar el producto del carrito. Por favor, inténtelo nuevamente.'
        );
        onFinish(error);
      });
  }

  onShoppingCartItemRemoveAsync(product);
}
